var socialFeed = document.querySelector(".json-fetcher");

if (socialFeed) {
  function jsonFetcher(element) {
    if (element) {
      var template = element.querySelector(".json-fetcher__template");
      var keys = element.dataset.keys.split(";");
      var api = element.dataset.api;

      $.get(api, function (data) {
        data.data.forEach(function (item) {
          var templateClone = template.cloneNode(true);
          keys.forEach(function (key) {
            try {
              var keyParts = key.split(".");
              var index = 0;
              var data = item;
              while (index + 1 <= keyParts.length) {
                data = data[keyParts[index]];
                index += 1;
              }
              templateClone.innerHTML = templateClone.innerHTML.replace(
                "__" + key + "__",
                data
              );
            } catch (error) {
              console.error(error);
            }
          });
          templateClone.innerHTML = templateClone.innerHTML.replace(
            "data-src",
            "src"
          );
          element.appendChild(templateClone.children[0]);
        });
        template.parentNode.removeChild(template);
      });
    }
  }

  jsonFetcher(document.querySelector(".json-fetcher"));
}
;
define("js/jsonFetcher", function(){});


var Cart = {};

Cart.on = function(eventName, callback) {
  if (!Cart.callbacks[eventName]) Cart.callbacks[eventName] = [];
  Cart.callbacks[eventName].push(callback);
  return Cart;
};

Cart.trigger = function(eventName, args) {
  if (Cart.callbacks[eventName]) {
    for (var i = 0; i<Cart.callbacks[eventName].length; i++) {
      Cart.callbacks[eventName][i](args||{});
    }
  }
  return Cart;
};

Cart.save = function() {
  localStorage.setItem('cart-items', JSON.stringify(Cart.items));
  Cart.trigger('saved');
  return Cart;
};

Cart.empty =  function() {
  Cart.items = [];
  Cart.trigger('emptied');
  Cart.save();
  return Cart;
};

Cart.indexOfItem = function(id) {
  for (var i = 0; i<Cart.items.length; i++) {
    if (Cart.items[i].id===id) return i;
  }
  return null;
};

Cart.removeEmptyLines = function() {
  newItems = [];
  for (var i = 0; i<Cart.items.length; i++) {
    if (Cart.items[i].quantity>0) newItems.push(Cart.items[i]);
  }
  Cart.items = newItems;
  return Cart;
};

Cart.addItem = function(item) {
  var index = Cart.indexOfItem(item.id);
  if (index === null) {
    Cart.items.push(item);
  } else {
    // replace quantity
    Cart.items[index].quantity = item.quantity;
  }

  Cart.removeEmptyLines();
  if (item.quantity > 0) {
    Cart.trigger('added', {item: item});
  } else {
    Cart.trigger('removed', {item: item});
  }

  Cart.save();
  return Cart;
};

Cart.itemsCount = function() {
  var accumulator = 0;
  for (var i = 0; i<Cart.items.length; i++) {
    accumulator += Cart.items[i].quantity;
  }
  return accumulator;
};

Cart.currency = '&pound;';

Cart.displayPrice = function(price) {
  if (price===0) return 'Free';
  var floatPrice = price/100;
  var decimals = floatPrice==parseInt(floatPrice, 10) ? 0 : 2;
  return Cart.currency + floatPrice.toFixed(decimals);
};

Cart.linePrice = function(index) {
  return Cart.items[index].price * Cart.items[index].quantity;
};

Cart.subTotal = function() {
  var accumulator = 0;
  for (var i = 0; i<Cart.items.length; i++) {
    accumulator += Cart.linePrice(i);
  }
  return accumulator;
};

Cart.init = function() {
  var items = localStorage.getItem('cart-items');
  if (items) {
    Cart.items = JSON.parse(items);
  } else {
    Cart.items = [];
  }
  Cart.callbacks = {};
  return Cart;
}

Cart.initJQuery = function() {
  var listItemActiveClass = 'b2b-form__list-item--active';
  var b2bPreviewModeClass = 'b2b-shop--preview-mode';
  var formPreviewModeClass = 'b2b-form--preview-mode';
  var cartPreviewModeClass = 'b2b-cart--preview-mode';
  var $b2bShop = $('.b2b-shop');
  var $b2bForm = $('.b2b-form');
  var $b2bCart = $('.b2b-cart');
  var $b2bPreviewMode = $('.js-b2b-preview-mode');
  var $b2bEditMode = $('.js-b2b-edit-mode');

  Cart.init();

  Cart.templateCompiler = function(a,b){return function(c,d){return a.replace(/#{([^}]*)}/g,function(a,e){return Function("x","with(x)return "+e).call(c,d||b||{})})}};

  // Cart.lineItemTemplate = "<tr>" +
  //   "<td><img src='#{this.image}' alt='#{this.label}' /></td>" +
  //   "<td></td>" +
  //   "<td>#{this.label}</td>" +
  //   "<td><button type='button' class='cart-add' data-id='#{this.id}' data-quantity='-1'>-</button>#{this.quantity}<button type='button' class='cart-add' data-id='#{this.id}' data-quantity='1'>+</button></td>" +
  //   "<td>#{this.quantity}</td>" +
  //   "<td>&times;</td>" +
  //   "<td>#{Cart.displayPrice(this.price)}</td>" +
  // "</tr>";

  Cart.lineItemTemplate = '<li>' +
    '<span class="b2b-cart__line-items-quantity">#{this.quantity} x </span> <span> &nbsp;  #{this.label} </span>' +
    '</li>';

  // $(document).on('click', '.cart-add', function(e) {
  //   e.preventDefault();
  //   var button = $(this);
  //   var item = {
  //     id: button.data('id'),
  //     // price: button.data('price'),
  //     quantity: button.data('quantity'),
  //     label: button.data('label'),
  //     image: button.data('image')
  //   }
  //   Cart.addItem(item);
  // });

  var calculateProductQuantity = function($this) {
    var $item = $this.closest('.b2b-form__list-item');

    var $quantityEl = $item.find('.b2b-form__product-quantity');
    var $selects = $item.find('.b2b-form__select');
    var quantity = 0;


    for (var i = 0; i < $selects.length; i++) {
      var selectQuantity = parseInt($selects[i].value);
      quantity += selectQuantity;
    }

    $quantityEl.text(quantity);

    if (quantity > 0) {
      $item.addClass(listItemActiveClass);
    } else {
      $item.removeClass(listItemActiveClass);
    }
  };

  $('.b2b-form__select').change(function(e) {
    e.preventDefault();
    var $this = $(this);
    var quantity = parseInt($this.val());

    var item = {
      id: $this.data('id'),
      quantity: quantity,
      label: $this.data('label')
    }

    // calculateProductQuantity($this);   // only for selects

    Cart.addItem(item);
  });

  $('.b2b-form__clear').click(function(e) {
    e.preventDefault();
    var $item = $(this).closest('.b2b-form__list-item');
    var $selects = $item.find('.b2b-form__select');
    $selects.val('0').trigger('change');
  });

  $('.b2b-cart__form').submit(function(e) {
    e.preventDefault();
  });

  /*$('.b2b-cart__preview').click(function(e) {
    e.preventDefault();

    $b2bEditMode.hide();
    $b2bPreviewMode.show();

    $b2bShop.addClass(b2bPreviewModeClass);
    $b2bForm.addClass(formPreviewModeClass);
    $b2bCart.addClass(cartPreviewModeClass);
  });*/
  if (getUrlParameter('preview') == 'true') {

    $b2bEditMode.hide();
    $b2bPreviewMode.show();

    $b2bShop.addClass(b2bPreviewModeClass);
    $b2bForm.addClass(formPreviewModeClass);
    $b2bCart.addClass(cartPreviewModeClass);
  }
  /*$('.b2b-cart__back-to-edit').click(function(e) {
    e.preventDefault();

    $b2bEditMode.show();
    $b2bPreviewMode.hide();

    $b2bShop.removeClass(b2bPreviewModeClass);
    $b2bForm.removeClass(formPreviewModeClass);
    $b2bCart.removeClass(cartPreviewModeClass);
  });*/

  $('.b2b-cart__submit').click(function(e) {
    e.preventDefault();

    var $submitButton = $(this);
    var $form = $('.b2b-cart__form');
    $('.b2b-shop__error').remove();

    var formData = {};
    $.each($form.serializeArray(), function (_, kv) {
      formData[kv.name] = kv.value;
    });

    formData.items = Cart.items;

    var data = JSON.stringify(formData);

    var loadingStateClass = 'b2b-cart__form--is-loading';
    $form.addClass(loadingStateClass);
    $form.find('input[type="submit"]').prop('disabled', true);

    $.ajax({
      type: 'POST',
      url: '/apps/esign-helper/b2b/order',
      data: data,
      dataType: 'json',
      success: function(response) {
        $form.removeClass(loadingStateClass);
        $form.find('input[type="submit"]').removeAttr('disabled');

        if (response && response.token) {
          Cart.empty();
          window.location = '/account/orders/' + response.token;
        }
      },
    }).fail(function(response) {
      $form.removeClass(loadingStateClass);
      $form.find('input[type="submit"]').removeAttr('disabled');

      if (response) {
        var json = JSON.parse(response.responseText);

        if (json && json.errors) {
          $submitButton.before('<div class="b2b-shop__error">' + json.errors.join(', ') + '</div>');
        }
      }
    });
  });

  // set select values from storage
  $('.b2b-form__select').val('');
  for (var i = 0; i < Cart.items.length; i++) {
    var item = Cart.items[i];
    var $select = $('.b2b-form__select[data-id="' + item.id +'"]');

    if ($select.length) {
      $select.val(item.quantity);

      var $quantityEl = $select.closest('.b2b-form__list-item').find('.b2b-form__product-quantity');
      var quantity = parseInt($quantityEl.text());

      $quantityEl.text(quantity + item.quantity);
      $quantityEl.closest('.b2b-form__list-item').addClass(listItemActiveClass);
    }
  }

  var updateReport = function() {
    var count = Cart.itemsCount();
    $('.cart-items-count').text(count);
    $('.cart-subtotal').html(Cart.displayPrice(Cart.subTotal()));
    if (count===1) {
      $('.cart-items-count-singular').show();
      $('.cart-items-count-plural').hide();
    } else {
      $('.cart-items-count-singular').hide();
      $('.cart-items-count-plural').show();
    }
  };

  var updateCart = function() {
    if (Cart.items.length>0) {
      var template = Cart.templateCompiler(Cart.lineItemTemplate);
      var lineItems = "";
      for (var i = 0; i<Cart.items.length; i++) {
        lineItems += template(Cart.items[i]);
      }
      $('.b2b-cart__line-items').html(lineItems);


      $('#order').val(lineItems);
      $('.b2b-cart__view').show();
      $('.b2b-cart__is-empty').hide();
    } else {
      $('.b2b-cart__view').hide();
      $('.b2b-cart__is-empty').show();
    }
  };

  var update = function() {
    updateReport();
    updateCart();
  };
  update();

  Cart.on('saved', update);

  return Cart;
};

$(function () {
  Cart.initJQuery();

  $('.b2b-form__product-image').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var productId = $this.data('product');
    var images = window.productImages[productId];

    $.fancybox.open(images);
  });

  // empty cart & add selected order to cart
  $('.js-b2b-reorder').click(function(e) {
    e.preventDefault();
    Cart.empty();

    var $this = $(this);
    var order = $this.data('order');
    var items = window.orders[order];

    for (var i = 0; i < items.length; i++) {
      Cart.addItem(items[i]);
    }

    window.location = $this.attr('href');
  });

  // Filters
  $('.b2b-tags-row__list .active a, .tags-table .active a').each(function(i) {
    var $this = $(this);

    var tag = $this.attr('title').replace('Remove tag ', '');
    var handle = tag.toLowerCase().replace(/ /g, '-');

    var $items = $this.closest('ul').find('li:not(.active) a');

    $items.each(function(j) {
      var $this = $(this);
      var href = $this.attr('href');
      href = href.replace(handle + '+', '');
      href = href.replace('+' + handle, '');

      $this.attr('href', href);
    });
  });

});


var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
};



// Cart.filterBrands = function() {
//
//   var searchArea = $('.knowledge__block');
//   $('.brandButton').click(function (e) {
//     e.preventDefault();
//
//     let brandId = e.currentTarget.dataset.brandId;
//
//     console.log(e);
//     console.log(brandId);
//
//
//     console.log('yeSdsfqsdfddssdfsdqfqD');
//
//
// // ${brandId}
//     console.log(document.querySelectorAll("[data-product-brand='64']"));
//     document.querySelectorAll("[data-product-brand='64']").hide();
//
//
//     // $('.product').hide();
//   });
//
//
// }
//
// Cart.filterBrands();

define("js/b2b-shop", function(){});

